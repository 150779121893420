import React from 'react'

import Banner from '../components/banner'
import TeamCard from '../components/cards/TeamCard'
import Layout from '../components/layout'

export default function TeamPage(): JSX.Element {
  return (
    <Layout>
      <div id="aboutus">
        <Banner bannerImage="Conversation" textColor="black" bannerText="Meet the Team" />

        <div className="teamCardContainer">
          <TeamCard memberid={0} />
          <TeamCard memberid={1} />
          <TeamCard memberid={2} />
          <TeamCard memberid={3} />
          <TeamCard memberid={4} />
          <TeamCard memberid={5} />
          <TeamCard memberid={6} />
        </div>
      </div>
    </Layout>
  )
}
